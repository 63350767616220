import React from "react"
import { graphql } from "gatsby"
import {
  About,
  Features,
  Founder,
  Hero,
  LogoSlider,
  Blog,
  Subscribe,
  Reviews,
} from "../components"

const IndexPageTemplate = ({ data }) => {
  const { blogPosts } = data
  const { hero, clients, about, founder, features, blog, reviews, subscribe } =
    data.markdownRemark.frontmatter

  return (
    <>
      <Hero data={hero} />
      <Reviews data={reviews} />
      <About data={about} />
      <Founder data={founder} />
      <Features data={features} />
      <Blog data={blog} posts={blogPosts} />
      <Subscribe data={subscribe} />
      <LogoSlider data={clients} />
    </>
  )
}

export default IndexPageTemplate

export const query = graphql`
  fragment ReviewsSection on MarkdownRemarkFrontmatter {
    reviews {
      title {
        blue
        red
      }
      items {
        job_title
        name
        text
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }

  fragment BlogSection on MarkdownRemarkFrontmatter {
    blog {
      title
      subtitle
      button
    }
  }

  fragment FeaturesSection on MarkdownRemarkFrontmatter {
    features {
      title {
        red
        blue
      }
      subtitle
      items {
        subtitle
        title
        icon {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }

  fragment FounderSection on MarkdownRemarkFrontmatter {
    founder {
      title
      name
      job_title
      linked_in
      twitter
      description
      embed_video_url
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }

  fragment AboutSection on MarkdownRemarkFrontmatter {
    about {
      title {
        blue
        red
      }
      subtitle
      button_title
      button_link
      items {
        icon {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
        subtitle
        title
      }
    }
  }

  fragment ClientsSection on MarkdownRemarkFrontmatter {
    clients {
      title
      logos {
        alt
        src {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }

  fragment HeroSection on MarkdownRemarkFrontmatter {
    hero {
      title
      subtitle
      button_title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }

  fragment SubscribeSection on MarkdownRemarkFrontmatter {
    subscribe {
      title
      subtitle
      button_title
      button_link
      button_plans_title
      button_plans_link
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      price
      price_info
      features {
        title
      }
    }
  }
`
